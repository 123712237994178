<script>
export default {
  name: 'password-write',
  data () {
    return {
      user: {
        confirm: '',
        password: ''
      },
      errorConfirm: false,
      errorPassword: false,
      pass: 'password'
    }
  },
  methods: {
    see () {
      if (this.pass === 'password') {
        this.pass = 'text'
      }
    },
    hide () {
      if (this.pass === 'text') {
        this.pass = 'password'
      }
    },
    isPassword () {
      if (this.user.password.length < 6) {
        this.errorPassword = true
      } else {
        this.$emit('updatePassword', this.user.password)
        this.errorPassword = false
      }
    },
    isConfirm () {
      if (this.user.confirm.length < 6) {
        this.errorConfirm = true
      } else {
        this.$emit('updateConfirm', this.user.confirm)
        this.errorConfirm = false
      }
    }
  }
}
</script>

<template>
  <div class="password-write">
    <div class="begin">
      <div class="content">
        <div class="form">
          <div class="login-form">
            <div class="label-bloc flex flex-col mb-2">
              <label
                for="password"
                class="mb-2 tracking-wide"
              >
                {{ $t('passwordLogin') }}
              </label>
              <div class="input-bloc relative">
                <div class="absolute flex border border-transparent left-0 top-0 h-full w-12">
                  <div class="icon flex items-center justify-center rounded-tl-lg rounded-bl-lg z-10 h-full w-full">
                    <svg-icon
                      name="ic_lock-closed"
                      original
                      class="w-6 h-6"
                    />
                  </div>
                </div>
                <input
                  v-model="user.password"
                  id="password"
                  name="password"
                  :type="pass"
                  placeholder="**********"
                  minlength="6"
                  class="ipt relative w-full border h-16 rounded-lg focus:outline-none py-4 pr-12 pl-12"
                  :class="{'passwordError': errorPassword}"
                  @keyup="isPassword"
                >
                <div class="absolute flex border cursor-pointer border-transparent right-0 top-0 h-full w-12">
                  <div class="icon flex items-center justify-center rounded-tr-lg rounded-br-lg z-10 h-full w-full">
                    <svg-icon
                      v-if="pass==='password'"
                      name="ic_eye-open"
                      original
                      class="w-6 h-6"
                      @click="see"
                    />
                    <svg-icon
                      v-if="pass==='text'"
                      name="ic_eye-close"
                      original
                      class="w-6 h-6"
                      @click="hide"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="errorPassword"
              class="text-red-500 text-sm mt-2"
            >
              {{ $t('passwordErrorLogin') }}
            </div>
          </div>
          <br />
          <div class="login-form">
            <div class="label-bloc flex flex-col mb-2">
              <label
                for="password"
                class="mb-2 tracking-wide"
              >
                Confirmer le mot de passe
              </label>
              <div class="input-bloc relative">
                <div class="absolute flex border border-transparent left-0 top-0 h-full w-12">
                  <div class="icon flex items-center justify-center rounded-tl-lg rounded-bl-lg z-10 h-full w-full">
                    <svg-icon
                      name="ic_lock-closed"
                      original
                      class="w-6 h-6"
                    />
                  </div>
                </div>
                <input
                  v-model="user.confirm"
                  id="password"
                  name="password"
                  :type="pass"
                  placeholder="**********"
                  minlength="6"
                  class="ipt relative w-full border h-16 rounded-lg focus:outline-none py-4 pr-12 pl-12"
                  :class="{'passwordError': errorConfirm}"
                  @keyup="isConfirm"
                >
                <div class="absolute flex border cursor-pointer border-transparent right-0 top-0 h-full w-12">
                  <div class="icon flex items-center justify-center rounded-tr-lg rounded-br-lg z-10 h-full w-full">
                    <svg-icon
                      v-if="pass==='password'"
                      name="ic_eye-open"
                      original
                      class="w-6 h-6"
                      @click="see"
                    />
                    <svg-icon
                      v-if="pass==='text'"
                      name="ic_eye-close"
                      original
                      class="w-6 h-6"
                      @click="hide"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="errorConfirm"
              class="text-red-500 text-sm mt-2"
            >
              {{ $t('passwordErrorLogin') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
input {
  color: $black;
  font-weight: 500;
}
.icon, .ipt {
  background-color: $light_gray3;
}
.ipt:focus {
  border-color: $pass_yellow;
  opacity: 0.7;
}
.mailError, .passwordError {
  border-color: $pass_error_red;
  &:focus {
    border-color: $pass_error_red;
  }
}
</style>
