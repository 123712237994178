<template>
  <div class="success-add">
    <popup-base>
      <div class="begin bg-white p-12 m-auto rounded-lg w-1/2">
        <div class="icon m-auto">
          <svg-icon
            name="ic_feather_check-circle"
            original
            class="w-16 h-16 m-auto"
          />
        </div><br>
        <div class="title text-black text-center text-2xl mt-6 font-medium">
          {{ $t('success') }}
        </div><br>
        <div class="message text-black text-xl text-center mt-4 leading-relaxed" v-html="message">
        </div><br>
        <div class="bouton mt-4">
          <button-base
            :label="buttonLabel"
            @click.native="closeSuccess"
          />
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import ButtonBase from '@/components/helper/add/button-base'
export default {
  name: 'success-add',
  components: { ButtonBase, PopupBase },
  props: {
    message: String,
    buttonLabel: String
  },
  methods: {
    closeSuccess () {
      this.$emit('closeSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .bouton::v-deep {
    .button-base {
      text-align: -moz-center;
      text-align: -webkit-center;
      .page-button-real {
        width: 100%;
      }
    }
  }
</style>
