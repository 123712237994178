<script>
import ButtonBase from '@/components/helper/add/button-base.vue'
import PasswordWrite from '@/components/helper/login/password-write.vue'
import http from '../../plugins/http'
import apiRoute from '../../router/api-routes'
import SuccessAdd from '@/components/helper/success-add.vue'

export default {
  name: 'index',
  components: { SuccessAdd, PasswordWrite, ButtonBase },
  data () {
    return {
      error: false,
      loader: false,
      password: '',
      confirmation: '',
      errorMessage: '',
      isSuccess: false
    }
  },
  methods: {
    closeSuccess (value) {
      this.isSuccess = value
      this.$router.push({ path: '/login' })
    },
    updatePassword (vqlue) {
      this.password = vqlue
    },
    updateConfirm (value) {
      this.confirmation = value
    },
    sendLogin () {
      if (this.password === '' || this.confirmation === '') {
        this.error = true
        this.errorMessage = 'Veuillez remplir tous les champs'
      } else {
        this.loader = true
        if (this.password !== this.confirmation) {
          this.loader = false
          this.error = true
          this.errorMessage = 'Mot de passe et confirmation non conformes'
        } else {
          this.error = false
          http.post(apiRoute.baseURL + apiRoute.resetPassword, {
            refactorId: this.$route.query.refactId,
            token: this.$route.query.token,
            password: this.password
          }).then(response => {
            console.log(response)
            this.isSuccess = true
          }).catch(error => {
            console.log(error)
            this.loader = false
          })
        }
      }
    }
  }
}
</script>

<template>
  <div class="index-reset-password">
    <div class="begin flex items-center">
      <div class="part-1 w-1/2 h-screen">
        <div class="image">
        </div>
      </div>
      <div class="part-2 w-1/2 overflow-y-scroll">
        <div class="header m-auto px-24">
          <div class="logo">
            <svg-icon
              name="ic_logo"
              original
              class="w-1/3"
            />
          </div><br>
          <div class="body w-10/12 mt-12">
            <password-write @updatePassword="updatePassword" @updateConfirm="updateConfirm"></password-write>
            <br>
            <div
              v-if="error"
              class="text-red-500 text-center"
            >
              {{ errorMessage }}
            </div><br>
            <div class="login-button">
              <button-base
                :label="$t('continuousButton')"
                :is-loading="loader"
                @click.native="sendLogin"
              />
            </div>
          </div>
          <br>
          <div class="message leading-relaxed w-3/4">
            <span>{{ $t('reservedSpace') }}</span>
            <span class="email-contact text-black cursor-pointer font-semibold">
              <a href="mailto:contact@enabel.be" class="text-black"> contact@enabel.be</a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <success-add
      v-if="isSuccess"
      message="Votre mot de passe a été defini avec succès"
      button-label="CONNEXION"
      @closeSuccess="closeSuccess"
    ></success-add>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
.part-1 {
  background-color: $pass_login_background;
}
.image {
  background-image: url("../../assets/icons/ic_login-page.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
.login-button::v-deep {
  .button-base {
    .page-button-real {
      width: 100%;
      height: 4rem;
      h4 {
        font-weight: 600;
      }
    }
  }
}
</style>
