<template>
  <div class="button-base">
    <button
      class="btn page-button-real py-4 px-6 rounded-lg font-normal flex items-center justify-center"
      :style="{backgroundColor: background, color: fontcolor}"
      :class="{'border-dotted': borderDot,'border': !borderDot}"
    >
      <svg-icon
        v-if="showIcon"
        :name="nameIcon"
        original
        class="w-4 h-4 mr-2"
      />
      <h4
        v-if="!isLoading"
        class="font-normal text-lg"
      >
        {{ label }}
      </h4>
      <h6 v-if="isLoading">
        <loading
          :active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          background-color="transparent"
          color="#FFFFFF"
          loader="dots"
          :height="30"
        >
        </loading>
      </h6>
    </button>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'button-base',
  components: {
    Loading
  },
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    nameIcon: {
      type: String
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    // TODO HERE IF HE WANT PREVIOUS COLOR
    background: {
      type: String,
      default: '#0E6BA8'
    },
    fontcolor: {
      type: String,
      default: '#FFFFFF'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    borderDot: {
      type: Boolean,
      default: false
    },
    fullPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: '#FFFFFF'
    }
  },
  methods: {
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .border-dotted {
    border: solid $white 0;
  }
  .vld-overlay {
    position: relative;
  }
</style>
